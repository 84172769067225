import React from 'react';
import Layout from '../layouts/index';
import { DeadCenter } from '../layouts/deadcenter';

const NotFoundPage = (props) => (
    <Layout location={props.location}>
        <DeadCenter>
            <h1>NOT FOUND</h1>
            <p>This page has either moved or no longer exists</p>
        </DeadCenter>
    </Layout>
);

export default NotFoundPage;
